//使っていないよ
import { useEffect, useState } from "react";
import parse from 'html-react-parser';
import "react-responsive-carousel/lib/styles/carousel.min.css"; // requires a loader
import { Carousel } from 'react-responsive-carousel';
import settings from '../../settings.json';


//import firstImg from './../assets/img/0.jpg';
//import secondImg from './../assets/img/1.jpg';
//import secondImg from './../assets/img/hero_2.png';
//import thirdImg from './../assets/img/re_top_3.jpg';
import firstImg from './../assets/img/banner1.jpg';
import secondImg from './../assets/img/banner2.jpg';
import thirdImg from './../assets/img/banner3.jpg';

import './../common.css';
import './parts.css';

function Header() {
  //const [title, settitle] = useState('まるいち<span className="text-small">の</span>お仏壇');

  useEffect(() => {
    console.log('Header');
    //モバイル
    if (navigator.userAgent.match(/iPhone|Android/)) {
    
    }

    // HERO 1 CSS変数使用、@media 使わなくても綺麗にレイアウト整う
    let w = document.getElementById('root').clientWidth; // 900
    let b = w / 28.125;
    let r = w / 18.75;
    let size = w / 17.578;
    let space = w / 160.71;
    document.documentElement.style.setProperty('--carousel-text-bottom', String(b)+'px'); // 2rem-->32px
    document.documentElement.style.setProperty('--carousel-text-right', String(r)+'px'); // 3rem-->48px
    document.documentElement.style.setProperty('--carousel-text-size', String(size)+'px'); // 3.2rem-->51.2px
    document.documentElement.style.setProperty('--carousel-text-space', String(space)+'px'); // .35rem-->5.6px

    {/*
    // HERO 2
    let hero_arg_plus = 3;
    document.documentElement.style.setProperty('--carousel-2-img-top', String(w / 8.88 -hero_arg_plus)+'px'); //あかね
    document.documentElement.style.setProperty('--carousel-2-font-1-left', String(w / 14)+'px');
    document.documentElement.style.setProperty('--carousel-2-font-1-top', String(w / 35.47 -hero_arg_plus)+'px');
    document.documentElement.style.setProperty('--carousel-2-font-size', String(w / 25)+'px');
    document.documentElement.style.setProperty('--carousel-2-span-font-size', String(w / 34.6)+'px');
    
    document.documentElement.style.setProperty('--carousel-2-2-font-size', String(w / 20)+'px'); // 価格
    document.documentElement.style.setProperty('--carousel-2-2-bottom', String(- (w / 2.24 -hero_arg_plus))+'px');
    document.documentElement.style.setProperty('--carousel-2-2-right', String(w / 30)+'px');

    document.documentElement.style.setProperty('--carousel-2-3-bottom', String(- (w / 2.05 -hero_arg_plus))+'px'); // 納品料こみ
    document.documentElement.style.setProperty('--carousel-2-3-right', String(w / 18)+'px');
    
    //HERO 3
    document.documentElement.style.setProperty('--carousel-3-1-left', String(w / 6.45)+'px');
    document.documentElement.style.setProperty('--carousel-3-1-top', String(w / 5.9)+'px');
    document.documentElement.style.setProperty('--carousel-3-1-text-space', String(w / 200)+'px');
    document.documentElement.style.setProperty('--carousel-3-1-text-size', String(w / 21)+'px');
    document.documentElement.style.setProperty('--carousel-3-1-span-text-size', String(w / 27.77)+'px');

    document.documentElement.style.setProperty('--carousel-3-2-left', String(w / 6.45)+'px');
    document.documentElement.style.setProperty('--carousel-3-2-top', String(w / 4.1)+'px');
    document.documentElement.style.setProperty('--carousel-3-2-text-size', String(w / 24)+'px');
     */}

    // menu トップだけ CSS
    let now_url = window.location.pathname;
    if(now_url==='/' ||  now_url==='/_dev'){
      document.getElementsByClassName('hero')[0].style.display = 'initial';
      document.getElementsByClassName('menu')[0].style.marginTop = '0px';
    }


    //DEBUG
    /* hero画像縦サイズ
          img tag
          width: 100%;
          height: 150px;
          object-fit: cover;
          object-position: 100% 5%;
      }
    */
    
      
    if(settings.DEV==='/_dev'){
      let top_img_elm = document.getElementById('go_top');
      top_img_elm.setAttribute('href', settings.DEV);
    }


  }, []);


  const fetchData = async (paramerter, value) => {
    console.log('_header_1');
  };

  //ページネーションボタン
  const handlePageClick = async (data) => {
    console.log('_header_2');
  };


  //カルーセル
  const [intervalz, setIntervalz] = useState(3000);

  const onChange = (index, item) => {
    setIntervalz(item.props["data-interval"]);
  };
  const onClickItem = (index, item) => {
    //console.dir(index);
    //console.dir(item);
    if(index===0){
      console.log('商品紹介1');
    }else if(index===1){
      console.log('商品紹介2');
      window.location.href = settings.DEV+"/item-list#998";
    }else if(index===2){
      console.log('商品紹介3');
      window.location.href = settings.DEV+"/item-list#998";
    }
    
  }



  return (
    <>
      <div className="hero">
        <Carousel
          onChange={onChange}
          onClickItem={onClickItem}
          stopOnHover={false}
          width={'100%'}
          thumbWidth={'30%'}
          autoPlay
          interval={intervalz}
          infiniteLoop={true}
          className='top-carousel'
          showThumbs={false}
        >
          <div data-interval={3000}>
            <div className="carousel-parent">
              <img src={firstImg} />
              {/*
                <div className="carousel-child">お仏壇のある暮らし</div>
              */}
              
            </div>
            
            {/*<p className="legend">上置お仏壇　〇〇</p>*/}
          </div>
          <div data-interval={4000}>
            <div className="carousel-parent">
              <img className="carousel-child-2-0" src={secondImg} />
              {/*
                <div className="carousel-child-2-1">あかね<span>セット</span></div>
                <div className="carousel-child-2-2">￥99,800円<span>（税込）</span></div>
                <div className="carousel-child-2-3">仏具・納品料込み</div>              
              */}

            </div>
            {/*<p className="legend">上置お仏壇　〇〇</p>*/}
          </div>
          <div data-interval={4000}>
            <div className="carousel-parent">
              <img className="carousel-child-3-0" src={thirdImg} />
              {/*
                <div className="carousel-child-3-1"><span>まるいちの</span>998シリーズ 全5種類</div>
                <div className="carousel-child-3-3">仏具・納品料込み ￥99,800円<span>（税込）</span></div>              
              */}

            </div>
            {/*<p className="legend">上置お仏壇　〇〇</p>*/}
          </div>
        </Carousel>
      </div>

      <header>
        <div className="header">
          {/* <h1>{parse(title)}</h1> */}
          <div className="menu">
            <div>
              <a href={settings.DEV+"/#__1"}>8つの特徴</a>
            </div>
            <div>
              <a href={settings.DEV+"/features?textKey=8"}>ショールーム</a>
            </div>
            <div>
              <a href={settings.DEV+"/item-list"}>仏壇・仏具</a>
            </div>
            <div>
              <a href={settings.DEV+"/about"}>会社概要</a>
            </div>
            <div>
              <a href={settings.DEV+"/access"}>アクセス</a>
            </div>
          </div>

        </div>
      </header>

    </>
  );
}

export default Header;
